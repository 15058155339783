import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';

function PermanentLicence() {
  const [open, setOpen] = useState(false);

  return (
    <section className="py-2">
      <Card>
        <Card.Header>
          <h2 class="mb-0">
            <button
              className="btn btn-link btn-block text-warning text-left"
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              Required Documents for Permanent License
            </button>
          </h2>
        </Card.Header>

        <Collapse in={open}>
          <div id="example-collapse-text">
            <Card.Body>
              <p className="small-txt">Application Form (A) within FileCover</p>
              <p className="small-txt">
                Original Learner Permit of at least 6 weeks.
              </p>
              <p className="small-txt">Medical Certificate</p>
              <p className="small-txt">
                3 Passport size fresh Photo Graphs (Attested)
              </p>
              <p className="small-txt">An Attested Copy of C.N.I.C.</p>
              <p className="small-txt">
                Paste a Ticket of Mentioned Fee on License Document.
              </p>
              <p className="small-txt">
                Slip to deposit Rs. 100 in any branch of Habib Bank (Account
                Number = 01277901547301)(only for Lahore)
              </p>
            </Card.Body>
          </div>
        </Collapse>
      </Card>
    </section>
  );
}

export default PermanentLicence;
