import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';

function DoplicateLicence() {
  const [open, setOpen] = useState(false);

  return (
    <section className="py-2">
      <Card>
        <Card.Header>
          <h2 class="mb-0">
            <button
              className="btn btn-link btn-block text-warning text-left"
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              Required Documents for Duplicate License
            </button>
          </h2>
        </Card.Header>

        <Collapse in={open}>
          <div id="example-collapse-text">
            <Card.Body>
              <p className="small-txt">
                Required Duplicate Application Form within FileCover
              </p>
              <p className="small-txt">An attested copy of C.N.I.C.</p>
              <p className="small-txt">
                2 Attested fresh Photographs (Passport size).
              </p>
              <p className="small-txt">
                Report from Computer section which expresses the expiry date of
                license.
              </p>
              <p className="small-txt">
                FIR (Lost License report) of the Police Station.
              </p>
              <p className="small-txt">
                Tickets will be pasted according to the schedule.
              </p>
            </Card.Body>
          </div>
        </Collapse>
      </Card>
    </section>
  );
}

export default DoplicateLicence;
