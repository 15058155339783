import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';

function InternationalLicence() {
  const [open, setOpen] = useState(false);

  return (
    <section className="py-2">
      <Card>
        <Card.Header>
          <h2 class="mb-0">
            <button
              className="btn btn-link btn-block text-warning text-left"
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              Required Documents for International License
            </button>
          </h2>
        </Card.Header>

        <Collapse in={open}>
          <div id="example-collapse-text">
            <Card.Body>
              <p className="small-txt">Application Form (A) within FileCover</p>
              <p className="small-txt">An attested copy of C.N.I.C.</p>
              <p className="small-txt">A copy of Valid Driving License</p>
              <p className="small-txt">A copy of Pakistani Passport valid</p>
              <p className="small-txt">
                2 attested photo graph (Passport Size)
              </p>
              <p className="small-txt">Rs. 66 Court Fee Ticket.</p>
              <p className="small-txt">
                Note: Applicant will bring his original C.N.I.C, Driving License
                and Passport with him.
              </p>
            </Card.Body>
          </div>
        </Collapse>
      </Card>
    </section>
  );
}

export default InternationalLicence;
