import React from 'react';
import Container from 'react-bootstrap/Container';
import Helmet from 'react-helmet';

import Layout from '../components/Layout';
import Documents from '../components/HowToApply/Documents';
import Limits from '../components/HowToApply/Linmit';
import Permanents from '../components/HowToApply/PermanentLicence';
import International from '../components/HowToApply/nternationalLicence';
import Doplicate from '../components/HowToApply/DoplicateLicence';

const howtoapply = () => {
  return (
    <div className="wrapper">
      <Helmet>
        <title>How To Apply</title>
      </Helmet>
      <Layout>
        <Container>
          <Documents />
          <Limits />
          <Permanents />
          <International />
          <Doplicate />
        </Container>
      </Layout>
    </div>
  );
};

export default howtoapply;
