import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';

const Documents = () => {
  const [open, setOpen] = useState(false);

  return (
    <section className="py-2">
      <Card>
        <Card.Header>
          <h2 className="mb-0">
            <button
              className="btn btn-link btn-block text-warning text-left"
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              Required Documents for Learner License
            </button>
          </h2>
        </Card.Header>

        <Collapse in={open}>
          <div id="example-collapse-text">
            <Card.Body>
              <p className="small-txt">A copy of C.N.I.C</p>
              <p className="small-txt">
                A Ticket of Rs.60 from any Post Office.
              </p>
              <p className="small-txt">
                Get a Code Book of Traffic Rules & Regulation from Traffic
                Police Office.
              </p>
              <p className="small-txt">
                Medical Certificate (by Authorized Medical Practitioner)
              </p>
            </Card.Body>
          </div>
        </Collapse>
      </Card>
    </section>
  );
};

export default Documents;
