import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';

const Limits = () => {
  const [open, setOpen] = useState(false);

  return (
    <section className="py-2">
      <Card>
        <Card.Header>
          <h2 class="mb-0">
            <button
              className="btn btn-link btn-block text-warning text-left"
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              Age Limits for Learner Permit
            </button>
          </h2>
        </Card.Header>

        <Collapse in={open}>
          <div id="example-collapse-text">
            <Card.Body>
              <p className="small-txt">
                Motor Cycle/ Motor Car: <strong>18 Years</strong>
              </p>
              <p className="small-txt">
                LTV (Rikshaw, Taxi, Tractor Commercial):{' '}
                <strong>21 Years</strong>
              </p>
              <p className="small-txt">
                Note: Learner permits are issued on Daily Bases which Validity
                is of 6 months.
              </p>
            </Card.Body>
          </div>
        </Collapse>
      </Card>
    </section>
  );
};

export default Limits;
